<template>
    <div>
        <!---->
        <TransitionGroup name="list"
                         tag="div"
                         @before-enter="onBeforeEnter"
                         @enter="onEnter"
                         @leave="onLeave">

            <div v-for="(blog, index) in allblogs"
                 :key="blog.WRFWPostsPK"
                 :data-index="index">

                <!--<h1>Hi</h1>
                <div>
                    <span>
                        BlogWrfwPostsPk {{BlogWrfwPostsPk}}<br />
                        BlogHierarchy: {{BlogHierarchy}}<br />
                        BlogLevel: {{BlogLevel}}
                    </span>
                    <hr />
                </div>-->
                <!--<span>store.increment() {{store.increment()}}</span>-->
                <!--v-if="CurrentRecursiveLevel == blog.Level"-->
                <div class="row TileArea">


                    <!--<span>
                        BlogWrfwPostsPk {{blog.WRFWPostsPK}}<br />
                        BlogHierarchy: {{blog.Hierarchy}}<br />
                        BlogLevel: {{blog.Level}}
                    </span>-->
                    <div class="col-lg-12 ">
                        <div :id="FormatIdentifier('ParentDiv', blog.WRFWPostsPK)"
                             class="shadow p-3 mb-5 bg-white rounded"
                             :data-level="blog.Level"
                             :data-blogkey="blog.WRFWPostsPK"
                             :data-postpk="blog.PostsPK"
                             :data-hierarchy="blog.Hierarchy"
                             :style="FormatTextIndentStyle(blog.Level)">

                            <span class="float-start WRFWCapitolItalicsArea">
                                <u>{{ blog.Alias }} &#9733; {{ getFormattedDate(blog.PostDateTime) }}</u>
                            </span>
                            <br />


                            <!--<blog-commands is-parent-node="blog.IsParentNode"
                            post-pk="blog.PostPk"
                            wrfw-Posts-pk="blog.WRFWPostsPK"
                            hierarchy="blog.Hierarchy"
                            parent-div-identifier="'ParentDiv' + blog.WRFWPostsPK"
                            level="blog.Level" />-->
                            <div class="shadow p-3 mb-5 bg-white rounded">
                                <span v-html="blog.PostHTML"
                                      class="WRFWCapitolArea"></span>
                                <br />
                                <a v-if="blog.CountOfChildren > 0 && blog.Level >= 2"
                                   @click.once="LoadMore(blog.Hierarchy, blog.Level, $event.target)"
                                   class="float-end">
                                    <br />
                                    <img width="120" height="30" src="/images/ButtonViewReplies_Custom1.png" />
                                </a>
                                <a @click="Reply(blog.Hierarchy, 'ParentDiv' + blog.WRFWPostsPK, blog.Level)"
                                   class="float-end">
                                    <br />
                                    <img width="60" height="30" src="/images/ButtonReply_Custom1.png" />
                                </a>
                            </div>
                            <empty-blog v-show="shouldShowEmptyBlog==blog.Hierarchy"
                                        :hierarchy="blog.Hierarchy"
                                        :parent-level="blog.Level"
                                        @CancelReply="DetachEmptyBlog"
                                        @ReloadGrid="LoadMore(blog.Hierarchy, blog.Level)" />
                        </div>
                        <!-- <div v-for="innerblog in allblogs" :key="innerblog.WRFWPostsPK">
                        <div v-if="HierarchyIsChild(innerblog.Hierarchy, innerblog.Level, blog.WRFWPostsPK, innerblog.WRFWPostsPK)==true">-->
                        <!--<span>
                            BlogWrfwPostsPk {{blog.WRFWPostsPK}}<br />
                            BlogHierarchy: {{blog.Hierarchy}}<br />
                            BlogLevel: {{blog.Level}}
                        </span>-->
                        <nested-blog :key="blog.WRFWPostsPK"
                                     :blog-wrfw-posts-pk="blog.WRFWPostsPK"
                                     :blog-hierarchy="blog.Hierarchy"
                                     :blog-level="blog.Level"
                                     :is-parent-node="blog.IsParentNode"
                                     :current-recursive-level="blog.Level + 1" />
                        <!--</div>
                        </div>-->

                    </div>

                </div>
            </div>
        </TransitionGroup>

    </div>
</template>
<script>

    import { defineComponent } from 'vue';
    import axios from 'axios';
    import * as $ from 'jquery';
    import UtilityMethods from "./UtilityMethods.js";
    import moment from 'moment';
    import gsap from 'gsap'
    import { store } from './store.js'

    export default {
        data: function () {
            return {
                allblogs: [],
                shouldShowEmptyBlog: false,
                shallshowviewreplies: true,
                store,

            };
        }
        , props: {
            BlogWrfwPostsPk: [Number, String],
            BlogHierarchy: [Number, String],
            BlogLevel: [Number, String],
            IsParentNode: [Number, String, Boolean],
            CurrentRecursiveLevel: [Number]
        }
        , watch: {
            allblogs: {
                handler: function (newVal, oldVal) {
                    this.$nextTick(() => {
                        //this.$forceUpdate();
                    });
                    return newVal;
                }
            }
        }
        , emits: ['CancelReply', 'ReloadGrid']
        , mixins: [UtilityMethods]
        , methods: {
            //IncrementCounter() {
            //    this.ElementCount = this.ElementCount + 1;
            //    return this.ElementCount;
            //},
            async GetBlogs() {
                const { data } = await axios.get('http://localhost:5202/api/BlogApi/GetBlogs',
                    {
                        params: {
                            level: this.CurrentRecursiveLevel
                            , hierarchy: this.BlogHierarchy
                            , url: "/RSS/Blog"
                        }
                    });
                //data.forEach(function (value) {
                //     IsThisPostAGroupingKey(value);
                //});
                this.allblogs = data;
            },
            async DetachEmptyBlog() {
                this.shouldShowEmptyBlog = "-1";
            },
            async Reply(hierarchy, stringIdentifier, level) {
                this.shouldShowEmptyBlog = hierarchy;
            },
            async LoadMore(hierarchy, level, ctrl) {
                $(ctrl).hide();
                this.shallshowviewreplies = false;
                this.CurrentRecursiveLevel = level;
                let { data } = await axios.get('http://localhost:5202/api/BlogApi/GetBlogs',
                    {
                        params: {
                            level: level + 1
                            , hierarchy: hierarchy
                            , url: "/RSS/Blog"
                        }
                    });

                const tempAllBlogs = [...this.allblogs];
                data = data.reverse();
                for (var newDataIndex = 0; newDataIndex < data.length; newDataIndex++) {
                    for (var originalData = 0; originalData < this.allblogs.length; originalData++) {
                        //alert(this.allblogs[originalData].Hierarchy);
                        //alert(data[newDataIndex].Hierarchy);
                        //alert(data[newDataIndex].Hierarchy.startsWith(this.allblogs[originalData].Hierarchy));
                        const countOfOriginal = this.allblogs[originalData].Hierarchy.split(",").length;
                        const countOfNewData = data[newDataIndex].Hierarchy.split(",").length;
                        const difference = countOfNewData - countOfOriginal;
                        if (data[newDataIndex].Hierarchy == this.allblogs[originalData].Hierarchy) {
                            //do nothing
                        } else if (data[newDataIndex].Hierarchy.startsWith(this.allblogs[originalData].Hierarchy) &&
                            (difference == 1)) {
                            tempAllBlogs.splice(originalData + 1, 0, data[newDataIndex]);
                        }
                    }
                }
                this.allblogs = tempAllBlogs;
                this.DetachEmptyBlog();
            },
            onBeforeEnter(el) {
                el.style.opacity = 0
                el.style.height = 0
            },
            onEnter(el, done) {
                gsap.to(el, {
                    opacity: 1,
                    height: '100%',
                    delay: el.dataset.index * 0.33,
                    onComplete: done
                })
            },
            onLeave(el, done) {
                gsap.to(el, {
                    opacity: 0,
                    height: '100%',
                    delay: el.dataset.index * 0.15,
                    onComplete: done
                })
            }
        },
        async beforeMount() {
            if (this.CurrentRecursiveLevel <= 2) {
                await this.GetBlogs();
            }
        }
    }
</script>
<style>
    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(20px);
        opacity: 0;
    }

    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }

    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
</style>