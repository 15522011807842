<template>

    <div>
        
        <div class="animate__animated animate__fadeInLeft">
            <div class=" Tile ">
                <h3>Here is with animate.css</h3>
            </div>
            
        </div>
        <!--<div class="fade-in">
            <h3>Here is another example with just css</h3>
        </div>-->
        <!--<transition id="Tile" appear
                    appear-active-class="fade-enter-active"
                    @before-appear="beforeAppear"
                    @appear="appear"
                    @after-appear="afterAppear">
            <div class="block">
                <h4>some content!!</h4>
            </div>
        </transition>-->
    </div>

</template>
<style>

</style>
<script>
    import { defineComponent } from 'vue';
    import axios from 'axios';
    import * as $ from 'jquery';
    import UtilityMethods from './UtilityMethods';
    import Vue from 'vue'
    import VueAppear from 'vue-appear'
    import { Keyframes } from 'vue-keyframes';
    import 'animate.css';

    Vue.use(VueAppear);
    Vue.use(Keyframes);

    export default defineComponent({
        el: '#Tile',
        methods: {
            beforeAppear: function (el) {
                /*  console.log('beforeAppear')
                alert('it beforeAppear')*/
            },
            appear: function (el) {
                /*  console.log('appear!')*/
            },
            afterAppear: function (el) {
                /*    console.log('afterAppear!')*/
            }
        }
    });


</script>
<!--<template>
    <div id="app" class="p-6">
        <button class="ml-4 bg-teal-500 px-6 py-2 rounded text-white shadow hover:bg-teal-600 focus:outline-none focus:shadow-outline"
                @click="show = !show">
            Toggle transition
        </button>
        <fade-transition mode="out-in">
            <div key="blue" v-if="show" class="box"></div>
            <div key="red" v-else class="red-box"></div>
        </fade-transition>
    </div>
</template>

<script>
    import FadeTransition from "./FadeTransition.vue";
    export default {
        name: "App",
        components: {
            FadeTransition
        },
        data() {
            return {
                show: true
            };
        }
    };
</script>
<style lang="scss">
    .box {
        width: 200px;
        height: 200px;
        margin-top: 20px;
        background-color: rgb(108, 141, 213);
        box-shadow: rgba(108, 141, 213, 0.5) 0px 6px 20px;
        border-radius: 10px;
    }

    .red-box {
        @extend .box;
        background-color: rgb(251, 17, 116);
        box-shadow: rgba(251, 17, 116, 0.5) 0px 6px 20px;
    }
</style>-->