<template>
    <div class='WRFWBlogEntry'
         :style='FormatTextIndentStyle(this.parentLevel)'
         data-blogkey='-1'
         data-postpk='-1'
         id="NewBlog">

        <div style='background-color:#fbfbfb'>
            <blockquote>
                <textarea style='width:100%!important;max-width:100%!important;'
                          id='NewBlogTextArea'
                          class="form-control"
                          rows="10"
                          v-model="postbody"></textarea>
                <div class=''>
                </div>
                <div>
                    <div class='QuoteActionArea'>
                        <a style='float:right;' @click.once="SubmitReply">
                            <img width="50" height="30" src="/images/Post_Custom1.png" />
                        </a>
                        <a style='float:left;' @click="Close">
                            <img width="70" height="30" src="/images/ButtonCancel_Custom1.png" />
                        </a>
                        <br />
                        <br />
                    </div>
                </div>
            </blockquote>
        </div>
        <hr />
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import axios from 'axios';
    import * as $ from 'jquery';
    import UtilityMethods from './UtilityMethods';

    export default defineComponent({
        data: function () {
            return {
                postbody: ""
            };
        },
        mixins: [UtilityMethods],
        props: ['hierarchy', 'parentLevel'],
        emits: ['CancelReply', 'ReloadGrid'],
        methods: {
            async Close() {
                this.$emit('CancelReply')
            },
            async SubmitReply() {
                $.ajax({
                    url: 'http://localhost:5202/api/BlogApi/PostBlog',
                    type: 'POST',
                    data: this.postbody,
                    headers: {
                        "hierarchy": this.hierarchy
                        , "level": this.parentLevel
                        , "url": '/RSS/Blog'
                        , "title": "Post Title"
                    },
                    asynch: false,
                    processData: false,
                    contentType: false,
                    timeout: 0,
                    //success: function (data) {
                    //    console.log(data);
                    //    alert(data);

                    //},
                    beforeSend: function (jqXHR) {
                        $('#loading-spinner').show();
                    },
                    error: function (jqXHR, textstatus, errorthrown) {
                        //DisplayError(jqXHR.statusText + '\n' + jqXHR.responseText);
                        $('#loading-spinner').hide();
                    },
                    always: function (data, textStatus, jqXHR) {
                        var it = 'string';
                    },
                    success: function (xhrResponse, status) {

                        //DisplaySuccess('Searched with: ' + $('#txt_findData').val());
                        //$('#loading-spinner').hide();
                        //if (level == 0) {
                        //    location.reload();
                        //}
                        ////get post PK attribute of
                        //var postPK = -1;
                        //var WRFWPostPK = -1;
                        ////get div to append to of
                        //$.each($('div[id^=ParentDiv]'), function(index, value) {
                        //    if ($(value).data('hierarchy') == hierarchy) {
                        //        WRFWPostPK = $(value).data('blogkey');
                        //        postPK = $(value).data('postpk');
                        //    }
                        //});
                        //$('#NewBlog').find('textarea').text('');
                        //$('#NewBlog').hide();
                        //todo refactor current method with vue show property UtilityMethods.methods.RemoveChildren(postPK, hierarchy, level, $('#RemoveChildrenLink' + postPK))
                        //UtilityMethods.methods.AttachChildBlogs(xhrResponse, 'ParentDiv' + WRFWPostPK.toString());
                    }
                });
                this.$emit('ReloadGrid');
            }
        },
    });
</script>

<style>
</style>