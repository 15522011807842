<style>
    
</style>
<script>
    import gsap from 'gsap'
    


    const menuLinks = [{ name: 'Contact Info', href: '', id: 1 },
        { name: 'Demo Videos', href: '', id: 2 }]

    export default {
        data() {
            return {
                query: ''
            }
        },
        computed: {
            computedList() {
                return menuLinks//.filter((item) => item.msg.toLowerCase().includes(this.query))
            }
        },
        methods: {
            onBeforeEnter(el) {
                el.style.opacity = 0
                el.style.height = 0
            },
            onEnter(el, done) {
                gsap.to(el, {
                    opacity: 1,
                    height: '1.6em',
                    delay: el.dataset.index * 0.15,
                    onComplete: done
                })
            },
            onLeave(el, done) {
                gsap.to(el, {
                    opacity: 0,
                    height: 0,
                    delay: el.dataset.index * 0.15,
                    onComplete: done
                })
            }
        }
    }
</script>

<template>
    <div>
        <div appear>
            <h4>Title</h4>
            <p>Lorem ipsum dolor sin</p>
        </div>
        <transition name="fade" appear>
            <h4>Title</h4>
            <p>Lorem ipsum dolor sin</p>
        </transition>
        <!--<TransitionGroup tag="ul"
                     :css="false"
                     @before-enter="onBeforeEnter"
                     @enter="onEnter"
                     @leave="onLeave"
                     appear>
        <li v-for="(item, index) in menuLinks"
            :key="item.id"
            :data-index="index">
            <a href="item.href">{{item.name}}</a>
        </li>
    </TransitionGroup>-->
    </div>
</template>