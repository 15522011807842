
import * as $ from 'jquery';
import moment from 'moment';

export default {
    methods: {
        AttachChildBlogs: function (jsonBlogData, divToAppend) {
            /*("MM/dd HH:mm")*/
            var blogsToAttach = $.parseJSON(jsonBlogData);
            $.each(blogsToAttach, function (index, value) {
                //var blogClassSize = '';
                //var backgroundColorClass = '';

                //if (value.Level == 2) {
                //    blogClassSize = 'Level2BlockQuoteText';
                //    backgroundColorClass = "Level2BlogPart";
                //} else if (value.Level == 3) {
                //    blogClassSize = 'Level3BlockQuoteText';
                //    backgroundColorClass = "Level3BlogPart";
                //} else {
                //    blogClassSize = 'Level4BlockQuoteText';
                //    backgroundColorClass = "Level4BlogPart";
                //}

                //var postsHtml = "<div style='padding-left:" + (10 * value.Level).toString() + "px!important;' class='WRFWBlogEntry' data-level='" + value.Level + "'  data-blogkey='" + value.WRFWPostsPK + "' data-postPk='" + value.PostsPK + "'  data-hierarchy='" + value.Hierarchy + "'   id=\"ParentDiv" + value.WRFWPostsPK + "\" >" +
                //    "<div class='" +
                //    backgroundColorClass.toString() +
                //    "'>" +
                //    "<blockquote> " +
                //    "<div " +
                //    " class='" + blogClassSize + "' > " +
                //    value.PostHTML +
                //    "</div> " +
                //    "<div class=' QuoteActionArea' >" +
                //    "<b>" + value.Alias + " &Star; " + moment(value.PostDateTime).format('M/D H:m') + "</b>" +
                //    "</div>" +
                //    "<div>" +
                //    "<div class=\" QuoteActionArea\">";

                //var conditionalReply = '';

                //if (value.Level <= 4) {
                //    if (value.IsParentNode) {
                //        /*  conditionalReply += "<a id=\"RemoveChildrenLink" + value.PostPk + "\" onclick=\"RemoveChildren(" + value.PostPk + " ,'" + value.Hierarchy + "'," + value.Level + " ,this);\"  class='hidden RemovePostHideArrow' >&UpTeeArrow;</a>";*/

                //        conditionalReply += "<a onclick=\"LoadMore(" + value.PostPk + ",'" + value.Hierarchy + "','" + value.Level + "','ParentDiv" + value.WRFWPostsPK + "', this)\"  class=''><img width=\"100\" height=\"20\" src=\"/images/ButtonViewReplies_Custom1.png\" /></a>";
                //        if (value.Level <= 3) {
                //            /*         conditionalReply += '&zhcy;';*/
                //        }
                //    }
                //}

                //if (value.Level <= 3) {
                //    conditionalReply += "<br />";
                //    conditionalReply += "<a onclick=\"Reply('" + value.Hierarchy + "',ParentDiv" + value.WRFWPostsPK + ", '" + value.Level + "')\" class=''><img width=\"50\" height=\"20\" src=\"/images/ButtonReply_Custom1.png\" /></a>";
                //}

                //postsHtml += conditionalReply;
                //postsHtml += "</div>" +
                //    " </div> " +
                //    " </blockquote> " +
                //    " </div> " +
                //    " <hr/> " +
                //    " </div> ";
                //$(postsHtml).appendTo($('#' + divToAppend));
            });
        },
        FormatIdentifier(StringIdentifier, Key) {
            return StringIdentifier + Key;
        }, HierarchyIsChild(InnerHierarchy, InnerLevel, OutterWRFWPostsPK, InnerBlogWrfwPostsPk) {
            if (InnerLevel == -1) {
                return true;
            }else if (OutterWRFWPostsPK == InnerBlogWrfwPostsPk) {
                return false;
            } else {
                return InnerHierarchy.toString().startsWith(OutterWRFWPostsPK + ',');
            }

        },
        FormatTextIndentStyle(blogLevel) {
            return "padding-left: (" + (5 * blogLevel) + "px!important";
        },
        getFormattedDate(date) {
            return moment(date).format("MM/DD HH:mm");
        }
    }
};