<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="texteron-italic float-end">...endless Demos</h3>

                <br />
            </div>
        </div>
        <!--<div class="row">
            <div class="col-12">
                <hr class="ShadowHR" />
            </div>
        </div>-->
        <div v-masonry transition-duration="0.3s"
             item-selector=".item"
             :origin-top="true"
             :horizontal-order="false"
             class="row">
            <div v-masonry-tile
                 class="col-12 Tile m-12"
                 v-for="(post, index) in posts"
                 :key="post.id">
                <div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h3 :class="[ isEven(index) ? 'float-start text-left WRFWMenu' : 'float-end text-right WRFWMenu']">
                                    {{post.title}}
                                </h3>
                            </div>
                        </div>
                        <div class="row" v-if="isEven(index)">
                            <div class="col-6">
                                <p :class="[ isEven(index) ? 'float-start text-left texteron-italic' : 'float-end text-right texteron-italic']">
                                    {{post.content}}
                                    <br />
                                    <div v-if="!!post.projectURL">
                                        <a :href="post.projectURL"><h3>View Demo</h3></a>
                                    </div>
                                </p>
                            </div>
                            <div class="col-6">
                                <iframe class="VideoPlayer"
                                        :src="post.video">
                                </iframe>
                            </div>
                        </div>
                        <div class="row" v-if="isOdd(index)">
                            <div class="col-6">
                                <iframe class="VideoPlayer"
                                        :src="post.video">
                                </iframe>
                            </div>
                            <div class="col-6">
                                <p :class="[ isOdd(index) ? 'float-start text-left texteron-italic' : 'float-end text-right texteron-italic']">
                                    {{post.content}}
                                    <br />
                                    <div v-if="!!post.projectURL">
                                        <a :href="post.projectURL"><h3>View Demo</h3></a>
                                    </div>

                                </p>
                            </div>
                        </div>



                    </div>
                    <hr class="ShadowHR" />
                    <!--style="width: 18rem;"-->
                    <!--<img class="card-img-top"
                         src="http://via.placeholder.com/350x150"
                         alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">
                            <strong>{{ post.title }}</strong>
                        </h5>
                        <p class="card-text">{{ smartTrim(post.content, 100) }}</p>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import { defineComponent } from 'vue';
    /*    import { Vue } from 'vue';*/
    import axios from 'axios';
    import * as $ from 'jquery';
    import UtilityMethods from "./UtilityMethods.js";

    //var VueMasonryPlugin = window["vue-masonry-plugin"].VueMasonryPlugin
    //window.Vue.use(VueMasonryPlugin)

    /*    var vm = new window.Vue({*/
    export default defineComponent({
        /*el: '#vue-instance',*/
        data: function () {
            return {
                posts: [],
                postsOriginal: [
                    {
                        title: `Pie charts and Datagrids`,
                        content: `In this demo I use an MVC .net framework 4 project, parse an text file, load it to the
                          database using SSIS, run a threaded console app to get webservice data from UPS webservices, then present it using Google Charts
                          and a DataTables js plugin.`,
                        id: -1,
                        video: `https://www.youtube.com/embed/MT-HoljGOP4`,
                        projectURL: `https://bluekernelit.net/JimsFreePA/Patriots/YorkCountyRegistrationResults`
                    },
                    {
                        title: `Google Big Query`,
                        content: `Here I ingest a text file to google big query, have it geo-coded, then run a console app
                          that runs a query for data warehousing, saves the results, then run a weighted rank query and display the results
                          using Geo shapes and Google Maps.`,
                        id: -1,
                        video: `https://www.youtube.com/embed/oUWqj3NU2D0`,
                        projectURL: 'https://bluekernelit.net/voterturnout'
                    },
                    {
                        title: `The Haversine Euclidian Geometry Example`,
                        content: `Here I display parsing a text file, running the Haversine algorithm, utilizing Google Web Services
                          and then sort a walklist into perfect walking order.`,
                        id: -1,
                        video: `https://www.youtube.com/embed/4tQgbCqYZJ0`,
                        projectURL: ''
                    },
                    {
                        title: `Vue & Front End Blog`,
                        content: `This demo shows a recursive Vue component, its middle tier, and a nice
                          backend CTE query.`,
                        id: -1,
                        video: `https://www.youtube.com/embed/ZFs9T4ikrhI`, 
                        projectURL: ''
                    },
                    {
                        title: `A look at a CTE Query`,
                        content: `In this quick video I talk about a CTE query, I'm a stickler for SQL, you can
                                do so much in SQL that replaces pages of middle tier and front end logic.
                                I also compare my custom hierarchy with the hierarchy SQL data type`,
                        id: -1,
                        video: `https://www.youtube.com/embed/5V4oyvzsKj8`,
                        projectURL: ''
                    },
                    {
                        title: `Manhattan Distance, SQL, and Class based Validation`,
                        content: `Here I talk about the ultra simple Manhattan Distance algorithm, in a .net framework 4.8
website.  I talk about my own usage of "NoValidate" and CSS based Forms Authentication.  I show a good implementation of dynamically
generated test data.  I talk briefly about project documentation and work I would assign.`,
                        id: -1,
                        video: `https://www.youtube.com/embed/vWQezQrNt2k`,
                        projectURL: ''
                    }
                ]
            };
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
            this.getPosts()
        },
        methods: {
            getPosts() {
                for (var i = 0; i < this.postsOriginal.length; i++) {
                    this.postsOriginal.id = i;
                    this.posts.push(this.postsOriginal[i]);
                }
                return this.posts;
            },
            handleScroll() {
                let scrollHeight = window.scrollY
                let maxHeight = window.document.body.scrollHeight - window.document.documentElement.clientHeight
                if (scrollHeight >= maxHeight - 200) {
                    this.getPosts()
                }
            },
            smartTrim(string, maxLength) {
                var trimmedString = string.substr(0, maxLength);
                return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
            },
            isEven(i) {
                return i % 2 === 0;
            },
            isOdd(i) {
                return i % 2 === 1;
            }
        }
    });
</script>