import Vue from 'vue';
import VueAppear from 'vue-appear';
import { VueMasonryPlugin } from 'vue-masonry';


Vue.config.productionTip = false;
//Vue.component('users-grid', require('./components/UsersGrid.vue').default);
//Vue.component('user-card', require('./components/UserCard.vue').default);
//Vue.component('users-card-grid', require('./components/UserCardGrid.vue').default);
/*Vue.component('blog-commands', require('./components/BlogCommands.vue').default);*/
Vue.component('empty-blog', require('./components/EmptyBlog.vue').default);
Vue.component('nested-blog', require('./components/NestedBlog.vue').default);
Vue.component('vertical-menu', require('./components/VerticalMenu.vue').default);
Vue.component('transition-tile', require('./components/TransitionTile.vue').default);
Vue.component('neverending-scroller', require('./components/NeverendingScroller.vue').default);
Vue.use(VueMasonryPlugin);
Vue.use(VueAppear);
window.Vue = Vue;
